/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { breakpoint, font } from './globals';
import { getBodyStyle, getColor, Theme } from './theme';
import { yesNoIcons } from './icons';

export const tables = css<{ theme: Theme }>`
  .wp-block-columns-is-layout-flex {
    display: flex;
    justify-content: center;
  }

  .wp-block-columns-is-layout-flex:has(.has-horizontal-align-left) {
    justify-content: flex-start;
  }

  .wp-block-columns-is-layout-flex:has(.has-horizontal-align-right) {
    justify-content: flex-end;
  }
  ${yesNoIcons};

  .simple-table-widget {
    margin: 24px 0;
    font-family: ${font.graphikCond};

    h3 {
      margin: 0;
      padding: 0 16px 8px 16px;
      ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};

      
      @media(${breakpoint.mmMin}) {
        padding: 8px 24px 12px 24px;
        ${getBodyStyle('graphikCond', { default: 'xlarge' }, 'semiBold')};
      }
    }

    mark {
      display: block;
      ${getBodyStyle('graphikCond', { default: 'xsmall' }, 'semiBold')};
      color: ${getColor('brandPrimary')};

      & + br {
        display: none;
      }
  }

    table {
      border-collapse: collapse; 
      width: 100%;

      ul, ol {
        margin: 0;
      }
    }

    tr:has(.has-light-green-cyan-color, .has-vivid-green-cyan-color) {
        background-color: ${getColor('surfaceSecondary')} !important;
        border-top: 2px solid ${getColor('textLink')};
      } 

    tr:not(:has(th)) {
      border-top: 2px solid ${getColor('borderSecondary')};

      &:has(.has-light-green-cyan-color, .has-vivid-green-cyan-color) {
        border-top: 2px solid ${getColor('borderSecondary')};
      }
    }

    tr:not(:has(th)):nth-child(1) {
      border-top: 2px solid ${getColor('textLink')};

      &:has(.has-light-green-cyan-color, .has-vivid-green-cyan-color) {
        border-top: 2px solid ${getColor('textLink')};
      }
    }
    
    tr:nth-child(even) {
      background-color: ${getColor('surfaceSecondary')}
    }

    th {
      font-weight: 600;
      line-height: 19px;
      padding: 24px 16px;
      text-align: left;
    }

    td {
      padding: 24px 16px;
      ${getBodyStyle('graphikCond', { default: 'default' }, 'regular')};
    }

    td:nth-child(1),
    th:nth-child(1) {
      width: 50%;
      line-height: 19px;
      position: relative;
      &::after {
      content:"";
      border-right: 1px solid ${getColor('borderSecondary')};
      position: absolute;
      top: 8px;
      bottom: 8px;
      right: 0px;
      }
    }

    @media(${breakpoint.mmMin}) {
      td:nth-child(1),
      th:nth-child(1) {
        width: 33%;
        padding: 24px 16px 24px 24px;
      }
    }
  }
`;
