/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { getColor, Theme } from 'styles/theme';
import { font, breakpoint } from './globals';

export const images = css<{ theme: Theme }>`
  .wp-block-image {
    @media (${breakpoint.mdMax}) {
      margin: 0 -16px !important;
    }
  }
  
  figure {
    margin: 0px;
    display: initial;
  }
  
  figcaption {
    font-family: ${font.graphikCond};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.58px;
    line-height: 21px;
    margin-top: 8px;
      
    @media (${breakpoint.mdMax}) {
      margin: 8px 16px 0;
    }
  }

  .image-credit {
    font-family: ${font.graphikCond};
    color: ${getColor('neutralDarkGray')};
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 24px;
      
    @media (${breakpoint.mdMax}) {
      margin: 0 16px 24px;
    }
  }
`;
