/* eslint-disable import/prefer-default-export */

import { css } from 'styled-components';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { primaryButtonLarge } from 'components/Globals/Base/Button/buttonStyles';
import { arrowRight } from 'styles/icons';
import { ctaCaption } from 'styles/paragraphs';
import { breakpoint } from 'styles/globals';

const rightBorderBottomGap = css<{ theme: Theme }>`
content: '';
position: absolute;
top: 0;
bottom: 4px;
right: 0;
width: 1px;
background-color: ${getColor('borderSecondary')};
margin: auto;
`;

const rightBorderTopGap = css<{ theme: Theme }>`
content: '';
position: absolute;
top: 4px;
bottom: 0;
right: 0;
width: 1px;
background-color: ${getColor('borderSecondary')};
margin: auto;
`;

export const comparisonTableStyles = css<{ theme: Theme }>`

.comparison-table-group-widget {
  margin: 24px 0;
}

.sponsored-disclosure {
  ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
  text-align: center;
  background-color: ${getColor('accentPrimary')};
  color: ${getColor('textWhite')} !important;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 8px;
  line-height: 24px;
  max-width: calc(100vw - 48px);
  margin: 0!important;
  
  @media (${breakpoint.mdMin}) {
    max-width: 100%;
  }
}

.comparison-table-widget {
  display: block;
  overflow-x: auto;
  max-width: calc(100vw - 48px);
  
  table {
    border-collapse: collapse;
    width: 100%;
    min-width: 600px;
    table-layout: fixed;
    
    thead > tr > th,
    tbody > tr > * {
      ${getBodyStyle('graphikCond', { default: 'small' }, 'semiBold')};
      line-height: 150%;
      letter-spacing: 0.5px;
      text-align: center;
      position: relative;
      width: calc(100% / 3);
      position: relative;
    }
    
    thead {
      border-bottom: 2px solid ${getColor('borderAccentPrimary')};
      & > tr > th {
        padding: 26px 24px 4px 24px;
        vertical-align: top;
        overflow: hidden;
        
        &:not(:nth-child(3))::after {
          ${rightBorderBottomGap}
        }
        
        img {
          margin-inline: auto;
          margin-bottom: 4px;
          max-width: 90px;
          aspect-ratio: 16/9;
          overflow: visible;
        }
        
        br {
          display: none;
        }
      }
    }
    
    tbody {
      & > tr > * {
        padding: 4px 24px;
      }
      
      & > tr:nth-child(4n+3),
      & > tr:nth-child(4n+4) {
        background-color: ${getColor('neutralLightGray2')};
      }
      
      & > tr:nth-child(2n) > * {
        ${getBodyStyle('graphikCond', { default: 'small' }, 'regular')};
        vertical-align: top;
        &:not(:nth-child(3))::after {
          ${rightBorderBottomGap}
        }
      }
      
      & > tr:nth-child(2n+1) > * {
        padding-bottom: 0;
        &:not(:nth-child(3))::after {
          ${rightBorderTopGap}
        }
      }
      
      & > tr:last-child > *:has(a) {
        ${ctaCaption};  
        padding: 8px 24px;
        a {
          ${primaryButtonLarge}
        }
      }
    }
  }
  
  ${Array.from({ length: 3 }, (_, index) => index + 1).map(
    (colNumber) => css<{ theme: Theme }>`
    &.selected-${colNumber} > table > tbody > tr {
      &:nth-child(4n+1) > *:nth-child(${colNumber}),
      &:nth-child(4n+2) > *:nth-child(${colNumber}) {
        background-color: ${getColor('shadeBrandPrimary100')};
      }
      
      &:nth-child(4n+3) > *:nth-child(${colNumber}),
      &:nth-child(4n+4) > *:nth-child(${colNumber}) {
        background-color: ${getColor('shadeBrandPrimary200')};
      }
    }
    
    &.selected-${colNumber} > table > thead > tr > th:nth-child(${colNumber}) {
      background-color: ${getColor('shadeBrandPrimary100')};
      padding-top: 4px;
      &::before {
        content: 'Current Review';
        color: ${getColor('textAccentPrimary')};
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.5px;
        display: block;
        margin-bottom: 4px;
      }
    }
    
    &.link-${colNumber} > table > tbody > tr:last-child > *:nth-child(${colNumber}) a {
      display: inline-flex;
      color: ${getColor('textLink')};
      background: none;
      padding: 0;
      margin: 8px 16px;
      font-size: 16px;
      &::after {
        ${arrowRight}
      }
      
      &:hover {
        text-decoration: underline;
      }
      
      &:focus,
      &:active {
        color: ${getColor('textSecondary')};
      }
    }
    `,
  )};
  
  @media (${breakpoint.mdMin}) {
    max-width: 100%;
  }
}
`;
