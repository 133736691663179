/* eslint-disable import/prefer-default-export */

import { css } from 'styled-components';
import { breakpoint } from './globals';

export const videos = css`
  .vodvideocontainer {
    width: 100%;
    padding-bottom: 0;

    @media (${breakpoint.mdMin}) {
      width: 512px;
      height: 310px;
    }
    
    @media(${breakpoint.lgMin}){
      width: 550px;
      height: 310px;
    }
    
    @media(${breakpoint.hybridMin}){
      width: 698px;
      height: 392px;
      margin-left: -20px;
    }


    @media (${breakpoint.xlMin}) {
      width: 800px;
      height: 480px;
      margin-left: -5px;
     
    }

    @media (${breakpoint.xxlMin}) {
      margin-left: -10px;
      width: 110%;
      height: 450px;
    
    }
  }
`;
